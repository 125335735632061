import { faAngleLeft, faAngleRight, faHouseChimney } from '@fortawesome/pro-regular-svg-icons'
import {
  Breadcrumbs as BaseBreadcrumbs,
  BreadcrumbsProps as BaseBreadcrumbsProps,
  Link as BaseLink,
  Typography,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { truncate } from '../../utils/truncate'
import { Icon } from '../icon/icon'
import { Link } from '../link/link'

const StyledBreadcrumbs = styled(BaseBreadcrumbs)`
  padding-right: ${props => props.theme.typography.pxToRem(30)};

  > ol {
    flex-wrap: wrap !important;

    > li {
      margin-bottom: 0;

      &::before {
        display: none;
      }

      > a {
        > p {
          line-height: 2;
        }

        > svg {
          margin-bottom: ${props => props.theme.typography.pxToRem(2)};
        }

        &:hover {
          color: ${props => props.theme.palette.secondary.dark};
          text-decoration: none;
        }
      }
    }

    .MuiBreadcrumbs-separator {
      margin-left: ${props => props.theme.typography.pxToRem(4)};
      margin-right: ${props => props.theme.typography.pxToRem(4)};
    }
  }
`

const StyledIconHome = styled(Icon)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  margin-bottom: ${props => props.theme.typography.pxToRem(3)};
  justify-content: center;
  align-items: center;
  display: flex;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(14)};
  }

  &:hover {
    color: ${props => props.theme.palette.secondary.dark};
  }
`

const StyledIconSeparator = styled(Icon)`
  margin-right: ${props => props.theme.typography.pxToRem(8)};
  font-size: ${props => props.theme.typography.pxToRem(10)};
  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(14)};
    margin-right: ${props => props.theme.typography.pxToRem(8)};
  }
`

const StyledLink = styled(BaseLink)`
  color: ${props => props.theme.palette.text.secondary};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: ${props => props.theme.typography.pxToRem(10)};
  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(14)};
  }

  &:hover {
    > p {
      color: ${props => props.theme.palette.secondary.dark};
      text-decoration: underline;
    }
  }
`

const StyledLinkText = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(10)};
  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(14)};
  }
`

export type BreadcrumbProps = {
  href: string
  home?: boolean
  text: string
}

export const Breadcrumb: React.FC<PropsWithChildren<BreadcrumbProps>> = ({ href, home, text, ...props }) => {
  const theme = useTheme()
  const iconSeparator = useMediaQuery(theme.breakpoints.down('xs')) ? faAngleLeft : faAngleRight

  return (
    <Link href={href} passHref {...props}>
      <StyledLink aria-label={home ? 'home' : text} rel="noopener" >
        {home ? (<StyledIconHome icon={faHouseChimney} />) :
          <React.Fragment>
            <StyledIconSeparator icon={iconSeparator} />
            <StyledLinkText variant="body1">{truncate(text, 15)}</StyledLinkText>
          </React.Fragment>
        }
      </StyledLink>
    </Link>
  )
}

export type BreadcrumbsProps = { children: React.ReactNode[] } & BaseBreadcrumbsProps

export const Breadcrumbs: React.VFC<BreadcrumbsProps> = ({ children, ...props }) => {
  const theme = useTheme()

  if (!children) {
    return null
  }

  // Mobile version to show top level for the current page.
  if (useMediaQuery(theme.breakpoints.down('sm'))) {
    const latestPos = children.length > 1 ? children.length - 1 : 0
    const secondLastChild = children.slice(latestPos - 1, latestPos)[0]

    if (!secondLastChild) {
      return (
        <StyledBreadcrumbs {...props} separator="" aria-label="breadcrumb">
          <Link href={'/'}>
            <StyledIconHome icon={faHouseChimney} />
          </Link>
          {children[3]}
        </StyledBreadcrumbs>
      )
    }

    return (
      <StyledBreadcrumbs {...props} separator="" aria-label="breadcrumb">
        {secondLastChild}
      </StyledBreadcrumbs>
    )
  }

  return (
    <StyledBreadcrumbs {...props} separator="" aria-label="breadcrumb">
      {children.map((child, index) => {
        if (index === 0 && React.isValidElement(child)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return (
            <React.Fragment key={index}>
              {React.cloneElement(child as React.ReactElement<any>, { home: true }, [...children])}
            </React.Fragment>
          )
        }

        return child
      })}
    </StyledBreadcrumbs>
  )
}