import styled from 'styled-components'
import { Grid, Typography } from '@material-ui/core'
import { rem } from '../../utils'

export const StyledTitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(23)};
  line-height: ${props => props.theme.typography.pxToRem(34)};
  font-weight: 500;
  padding: ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(20)} 0 ${props => props.theme.typography.pxToRem(20)};
  color: ${props => props.theme.palette.text.primary};
  opacity: 1;
  line-clamp: 3;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  text-align: center;

  ${props => props.theme.breakpoints.up('md')} {
    padding: 0;
    font-size: ${props => props.theme.typography.pxToRem(38)};
    margin: 0 0 ${props => props.theme.typography.pxToRem(13)};
    max-width: ${props => props.theme.typography.pxToRem(338)};
    text-align: left;
    line-height: ${props => props.theme.typography.pxToRem(48)};
  }

  ${props => props.theme.breakpoints.up(1025)} {
    width: 100%;
  }
`

export const StyledSubtitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(15)};
  max-width: ${props => props.theme.typography.pxToRem(426)};
  line-height: ${props => props.theme.typography.pxToRem(28)};
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  text-align: center;
  margin-top: ${props => rem(20, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    max-width: ${props => props.theme.typography.pxToRem(600)};
    font-size: ${props => props.theme.typography.pxToRem(17)};
    line-height: ${props => rem(35, props.theme)};
  }
  ${props => props.theme.breakpoints.up('md')} {
    max-width: ${props => props.theme.typography.pxToRem(470)};
    font-size: ${props => props.theme.typography.pxToRem(17)};
    line-height: ${props => rem(35, props.theme)};
    text-align: left;
  }
`

export const StyledBreadcrumbs = styled.div<{$glossary?: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 170px;
  z-index: 1;
  padding: 5px 0 5px 15px;
  background: ${props => props.$glossary ? 'none' : 'transparent linear-gradient(90deg, #f2f2f2 0%, #f2f2f200 100%) 0 0 no-repeat padding-box'};

  .MuiBreadcrumbs {
    &-ol {
      display: flex;
      flex-wrap: nowrap;

      > * {
        flex: 0 0 auto;
      }
    }
  }
`

export const StyledImage = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;
`

export const StyledContent = styled(Grid)<{$fw?: boolean}>`
  align-items: center;
  row-gap: ${props => props.theme.typography.pxToRem(30)};
  ${props => props.theme.breakpoints.between(0, 1025)} {
    row-gap: ${props => props.theme.typography.pxToRem(35)};
    width: ${props => props.theme.typography.pxToRem(485)};
    max-height: ${props => props.theme.typography.pxToRem(620)};
  }
  ${props => props.theme.breakpoints.between(600, 1025)} {
    align-items: ${props => props.$fw == true? 'center': 'flex-start'};
  }
  ${props => props.theme.breakpoints.up(1025)} {
    align-items: flex-start;
  }
`

export const BannerContainer = styled(Grid)`
  display: flex;
  max-width: ${props => props.theme.typography.pxToRem(1350)};

  ${props => props.theme.breakpoints.up('md')} {
    margin: auto;
  }
`

export const BannerImageContainer = styled(Grid)`
  position: relative;
  display: inline-block;
  clip-path: polygon(0 0, 100% 0, 100% 100%, ${props => rem(36, props.theme)} 100%);
  margin-left: ${props => props.theme.typography.pxToRem(24)};
  width: 100%;

  ${props => props.theme.breakpoints.up('md')} {
    position: absolute;
    top: 0;
    right: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, ${props => rem(96, props.theme)} 100%);
    width: ${() => (1090/1920) * 100}%;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    width: 54%;
  }
  ${props => props.theme.breakpoints.up(2200)} {
    width: 50%;
  }
`

export const StyledLinkSection = styled.div<{$glossary: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.$glossary ? 'flex-start' : 'center'};
  gap: ${props => props.theme.typography.pxToRem(20)};
  max-width: ${props => props.theme.typography.pxToRem(426)};
  padding-bottom: ${props => props.theme.typography.pxToRem(30)};
  margin-top: ${props => rem(28, props.theme)};
  flex-wrap: wrap;

  ${props => props.theme.breakpoints.up('sm')} {
    max-width: ${props => props.theme.typography.pxToRem(600)};
    padding-bottom: ${props => props.theme.typography.pxToRem(60)};
  }
  ${props => props.theme.breakpoints.up('md')} {
    max-width: ${props => props.theme.typography.pxToRem(470)};
    justify-content: flex-start;
    column-gap: ${props => props.theme.typography.pxToRem(30)};
    margin-top: ${props => props.theme.typography.pxToRem(45)};
    padding-bottom: ${props => props.theme.typography.pxToRem(30)};
  }

  .MuiButton-outlinedPrimary {
    padding: ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(11)} ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(15)};

    > span {
      font-size: ${props => props.theme.typography.pxToRem(18)};
    }
  }

  .MuiButton-outlinedSizeSmall {
    padding: ${props => props.theme.typography.pxToRem(17)} ${props => props.theme.typography.pxToRem(13)} ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(20)};

    > span {
      font-size: ${props => props.theme.typography.pxToRem(15)};
    }
  }
`

export const StyledBottomSpacer = styled(Grid)`
  width: 100%;
  clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
  background-color: #f8f8f8;
  height: ${props => props.theme.typography.pxToRem(420)};
  ${props => props.theme.breakpoints.up('md')} {
    transform: translateY(${props => props.theme.typography.pxToRem(-40)});
  }
`
