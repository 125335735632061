import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import Image from 'next/legacy/image'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import styled from 'styled-components'
import { rem } from '../../utils'
import { getDimensions } from '../../utils/getDimensions'
import { Button, ButtonProps } from '../button/button'
import { Contained } from '../contained/contained'
import { Link } from '../link/link'
import {
    BannerImageContainer,
    StyledBreadcrumbs,
    StyledImage,
    StyledLinkSection,
    StyledSubtitle,
    StyledTitle,
} from './StyledSecondaryBanner'

type LinkSectionProps = {
  //
} & React.ComponentProps<typeof StyledLinkSection>

type CtaLinkProps = {
  target?: string
} & ButtonProps 


const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
  <Button {...props} innerRef={ref}>
    {props.children}
  </Button>),
)

const CTALink: React.FC<PropsWithChildren<CtaLinkProps>> = ({ href, ...props }) => {
  return (
    <Link href={href || '#'} passHref>
      <ButtonLink { ...props}>{props.children}</ButtonLink>
    </Link>
  )
}

type TitleProps = {
  //
} & React.ComponentProps<typeof StyledTitle>

const Title : React.FC<PropsWithChildren<TitleProps>> = (props) => {
  return (
    <StyledTitle {...props} variant="h1">
      {props.children}
    </StyledTitle>
  )
}

type SubTitleProps = {
  //
} & React.ComponentProps<typeof StyledSubtitle>

const Subtitle: React.FC<PropsWithChildren<SubTitleProps>> = (props) => {
  return (
    <StyledSubtitle {...props} variant="body1">
      {props.children}
    </StyledSubtitle>
  )
}

type BannerImageProps = {
  breadcrumbs?: React.ReactNode
  whiteBg?: boolean | undefined
  src?: string
}  & React.ComponentProps<typeof StyledBannerContainer>

export const useContainerDimensions = ref => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions(ref))
    }

    if (ref.current) {
      setDimensions(getDimensions(ref))
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref])

  dimensions.height = dimensions.width * 620 / 1090

  return dimensions
}

const StyledBannerContainer = styled.div`
  position: relative;

  ${BannerImageContainer} {
    height: ${props => rem(160, props.theme)};

    ${props => props.theme.breakpoints.up('sm')} {
      height: ${props => rem(450, props.theme)};
    }
  }

  ${props => props.theme.breakpoints.up('md')} {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    ${BannerImageContainer}, & {
      height: ${props => rem(498, props.theme)};
    }
  }
  ${props => props.theme.breakpoints.up('lg')} {
    ${BannerImageContainer}, & {
      height: ${props => rem(520, props.theme)};
    }
  }
  ${props => props.theme.breakpoints.up(2200)} {
    ${BannerImageContainer}, & {
      height: ${props => rem(670, props.theme)};
    }
  }
`

export const StyledContentContainer = styled.div<{$glossary: boolean}>`
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.$glossary ? 'flex-start' : 'center'};

  ${props => props.theme.breakpoints.up('md')} {
    align-items: flex-start;
  }
`

const BannerImage: React.FC<PropsWithChildren<BannerImageProps>> = ({ breadcrumbs, glossary, src, children, ...props }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <StyledBannerContainer { ...props }>
      {src ? (
        <BannerImageContainer container item justifyContent="center" alignItems="flex-start" xs={12} md={8} xl={7}>
          {breadcrumbs && isMobile && (
            <StyledBreadcrumbs $glossary={glossary}>
              {breadcrumbs}
            </StyledBreadcrumbs>
          )}
          {src && (
            <StyledImage>
              <Image src={src} alt="" layout="fill" objectFit="cover" objectPosition="top right" priority />
            </StyledImage>
          )}
        </BannerImageContainer>
      ) : (
        <>
          {breadcrumbs && isMobile && (
            <StyledBreadcrumbs $glossary={glossary}>
              {breadcrumbs}
            </StyledBreadcrumbs>
          )}
        </>
      )}

      <Contained container>
        {!isMobile && breadcrumbs}
        <StyledContentContainer $glossary={glossary}>
          {children}
        </StyledContentContainer>
      </Contained>
    </StyledBannerContainer>
  )
}

export type SecondaryBannerProps = {image?:boolean; title?: boolean; subtitle?: boolean; section?:boolean; cta?: boolean; href?: string; src?: string; buttonProps?: CtaLinkProps; glossary?: boolean} & (
  // Image
  | ({image: true; glossary?: boolean} & BannerImageProps)
  // Title
  | ({title: true } & TitleProps)
  // Subtitle 
  | ({subtitle: true} & SubTitleProps)
  // CTA
  | ({cta: true; href: string; buttonProps?: CtaLinkProps})

  | ({section: true; glossary?: boolean} & LinkSectionProps)
)

export const SecondaryBanner: React.FC<PropsWithChildren<SecondaryBannerProps>> = ({ image, title, subtitle, section, cta, href, src, buttonProps, glossary, ...props }) => {
  if(title){
    return (
      <Title {...props} >
        {props.children}
      </Title>      
    )
  }

  if(subtitle){
    return (
      <Subtitle  {...props} >        
        {props.children}
      </Subtitle>
    )
  }

  if(cta){
    return (
      // @ts-ignore
      <CTALink href={href || '#'} { ...buttonProps}>
        {props.children}
      </CTALink>
    )    
  }

  if(image){
    return (
      <BannerImage glossary={glossary} src={src} {...props} >
        {props.children}
      </BannerImage>
    )
  }

  if(section){
    return (
      <StyledLinkSection $glossary={glossary} {...props} >
        {props.children}
      </StyledLinkSection>
    )
  }

  return null
}